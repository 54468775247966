<template>
    <vx-card style="min-height: 700px">
        <div class="vx-row">
            <div class="vx-col md:w-2/3">
                <div class="vx-col md:w-full sm:w-auto">
                    <vs-input class="w-full" data-vv-validate-on="blur" :disabled="this.isUpdate"
                              :label="$t('menuIntrusion.eventName')"
                              name="nameEventFireAlarm"
                              v-model="nameEventFireAlarm"
                              v-validate="'required'"/>
                    <span class="text-danger text-sm">{{ errors.first('nameEventFireAlarm') }}</span>
                </div>
            </div>
        </div>
        <div class="vx-row">
            <div class="vx-col md:w-1/3 mt-2">
                <label class="vs-input--label">{{ $t('menuIntrusion.branch') }}</label>
                <v-select class="" v-model="selectedOrganizationBranch"
                          :clearable="false"
                          :disabled="this.isUpdate"
                          :options="organizationBranchOptions">
                          <span slot="no-options">{{$t('menuOrganization.emptyList')}}</span>
                </v-select>
            </div>

            <div class="vx-col md:w-1/3 mt-2">
                <label class="vs-input--label mr-5">Camera</label>
                <vx-tooltip :title="$t('textCamera.instructionAiFE')"
                            color="warning"
                >
                    <feather-icon color="primary" style="vertical-align: bottom; width: 14px;color: #1aaa55"
                                  type="border"
                                  icon-pack="feather"
                                  icon="HelpCircleIcon"
                    >
                    </feather-icon>
                </vx-tooltip>
                <v-select class="" v-validate="'required'"
                          :disabled="this.isUpdate"
                          v-model="selectedCamera"
                          :clearable="false"
                          :options="cameraOptions">
                          <span slot="no-options">{{$t('menuOrganization.emptyList')}}</span>
                </v-select>
                <span class="text-danger text-sm">{{ errors.first('cameraName') }}</span>
            </div>
        </div>
        <div class="vx-row">

                <div :title="$t('menuIntrusion.utilization')" class="vx-col md:w-full mt-2">

                    <div class="vx-col md:w-full sm:w-auto mt-5 ml-2 color">
                        <span>{{ $t('menuIntrusion.utilization') }}:</span>
                    </div>

                        <WeekComponent :selectTimes="this.weekUse" :nameEventBus="nameEventBusUse"></WeekComponent>
                </div>

        </div>
        <div class="vx-row">
            <div class="vx-col w-full">
                <div class="mt-3 flex flex-wrap items-center justify-end mb-5">
                    <vs-button class="ml-auto mt-2" @click="formSubmitted" :disabled="!validateForm">{{
                            `${isUpdate ?
                                'Cập nhật' : 'Tạo mới'}`
                        }}
                    </vs-button>

                        <vs-button class="mr-5 ml-5 mt-2"
                                   type="border"
                                   @click="backToAiRes">{{ $t('button.back') }}
                        </vs-button>

                </div>
            </div>
        </div>
    </vx-card>
</template>


<script>
import vSelect from 'vue-select';
import {FormWizard, TabContent} from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import utils from "../../../components/utils";
import WeekComponent from "../../../components/week-time/WeekComponentNew";
import CanvasPoint from "../../../components/canvas-point/CanvasPoint";
import noImage from "../../../assets/images/pages/noImage"

export default {
    data() {
        return {
            allCameraOptions: [],
            checkImage: true,
            isUpdate: false,
            isLoadImg: false,
            isLoadWeekUse: false,
            isLoadWeekNotify: false,
            myImg: new Image(),
            nextButtonText: this.$t('menuIntrusion.nextButtonText'),
            backButtonText: this.$t('button.back'),
            finishButtonText: this.$t('button.save'),
            nameEventFireAlarm: null,
            pointAI: [],
            cameraOptions: [],
            cameraId: null,
            weekUse: {
                arr0: [],
                arr1: [],
                arr2: [],
                arr3: [],
                arr4: [],
                arr5: [],
                arr6: []
            },
            nameEventBusUse: 'update-data-week-use',
            weekNotify: {
                arr0: [],
                arr1: [],
                arr2: [],
                arr3: [],
                arr4: [],
                arr5: [],
                arr6: []
            },
            nameEventBusNotify: 'update-data-week-notify',
            email: '',
            emails: [],
            isMobileApp: true,
            organizationBranchId: null,
            size: "640X480",
            phoneNumber: null,
            period: 0,
            id: null,
            organizationBranchOptions: [],
            isCompleteDraw: false,
            canAddEvent: false,
            valueCurrentIdCam: 0,
        }
    },
    components: {
        CanvasPoint,
        WeekComponent,
        FormWizard,
        TabContent,
        vSelect
    },
    computed: {
        validateForm() {
            return !this.errors.any()
        },
        selectedCamera: {
            get() {
                if (this.cameraOptions.length > 0 && this.cameraId) {
                    return {
                        label: this.cameraOptions.find(x => x.value == this.cameraId).label,
                        value: this.cameraId
                    }
                }
            },
            set(obj) {
                this.cameraId = obj.value;
            }
        },

        selectedOrganizationBranch: {
            get() {
                return {
                    label: this.organizationBranchString(this.organizationBranchId),
                    value: this.organizationBranchId
                }
            },
            async set(obj) {
                this.organizationBranchId = obj.value;
                await this.getCamera();
                this.isLoadWeekUse = true;
                this.isLoadWeekNotify = true;
                if (!this.isUpdate) {
                    let cameraId = this.cameraOptions.length > 0 ? this.cameraOptions[0].value : null;
                    this.selectedCamera = {value: cameraId};
                } else {
                    this.selectedCamera = this.cameraOptions.find(x => x.value == this.cameraId);
                }
            }
        }
    },
    async created() {
        let branchList = JSON.parse(localStorage.getItem('branchInfoList') || '[]');
        if (branchList.length === 0) {
            return this.$vs.notify({
                text: this.$t('menuIntrusion.noBranch'),
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'danger'
            });
        }
        this.organizationBranchOptions = branchList.map(item => {
            return {value: item.id, label: item.organizationBranchName}
        });
        let branchId = this.$route.query.branch_id;
        let index = 0;
        if (branchId) {
            index = this.organizationBranchOptions.findIndex(item => item.value == branchId)
        }
        this.selectedOrganizationBranch = this.organizationBranchOptions[index > -1 ? index : 0];

        this.id = this.$route.query.id;
        let timeDuration = 30, timeSpans = [];
        for (let i = 0; i < 24 * 60; i = i + timeDuration) {
            timeSpans.push({value: i, label: utils.minuteToHour(i)});
        }
        this.timeSpans = timeSpans;
        if (this.id) {
            this.getAiRestric();
        }
    },
    methods: {
        organizationBranchString(id) {
            let organizationBranch = this.organizationBranchOptions.find(x => x.value === id);
            return (organizationBranch && organizationBranch.label) || this.$t('menuIntrusion.undefined');
        },
        getAiRestric() {
            this.$vs.loading();
            this.$crm.get(`/fire-detect/find-by-id/${this.id}`).then((response) => {
                let data = response.data;
                this.cameraId = data.cameraId;
                this.nameEventFireAlarm = data.name;
                this.weekUse = JSON.parse(data.timeUse);
                this.isLoadWeekUse = true;
                this.isLoadWeekNotify = true;
                this.isUpdate = true;
                this.$vs.loading.close();
            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            });
        },
        backToAiRes() {
            this.$router.push('/user/fire-alarm');
        },
        getCamera() {
            if (!this.organizationBranchId) return;
            this.cameraOptions = [];
            this.cameraId = null;
            return new Promise(resolve => {
                let that = this;
                let url = `/camera/find-by-organization-branch/${this.organizationBranchId}`;
                that.$vs.loading();
                that.$crm.post(url).then((response) => {
                    if (response.data.length > 0) {
                        let result = response.data;
                        if (this.organizationBranchId) result = result.filter(el => el.organizationBranchId === this.organizationBranchId);
                        that.allCameraOptions = result.map(item => {
                            return {value: item.id, label: item.cameraName}
                        });
                        that.allCameraOptions.map(x => {
                            this.$vs.loading();
                            this.$crm.get(`/camera/find-package-last-active?cameraId=${x.value}`).then((resp) => {
                                if(resp.data.cameraPackageResponseDTO.permissionFunctions && resp.data.cameraPackageResponseDTO.permissionFunctions.length > 0){
                                    resp.data.cameraPackageResponseDTO.permissionFunctions.map(m => {
                                        if(m.functionName.toLowerCase().includes('ai-fire-detect')){
                                            this.cameraOptions.push(x)
                                        }
                                    })
                                }
                                this.$vs.loading.close();
                            }).catch(() => {
                                this.$vs.loading.close();
                            });
                        })
                    }
                    resolve(true);
                    that.$vs.loading.close();
                }).catch((err) => {
                    that.$vs.loading.close();
                    return this.$vs.notify({
                        text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    });
                });
            })
        },
        formSubmitted() {
            if (!this.nameEventFireAlarm && !this.isUpdate) {
                return this.$vs.notify({
                    text: this.$t('menuIntrusion.notEventName'),
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            }
            if (this.weekUse.length == 0) {
                return this.$vs.notify({
                    text: this.$t('menuIntrusion.notTimeUse'),
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            }

            let check = false;
            for (const [_, valueTime] of Object.entries(this.weekUse)) {
                if(valueTime.length > 0){
                    check = true;
                }
            }

            if(!check){
                return this.$vs.notify({
                    text: this.$t('menuIntrusion.notTimeUse'),
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            }

            let dataRequest = {
                organizationBranchId: parseInt(this.organizationBranchId),
                name: this.nameEventFireAlarm,
                cameraId: this.cameraId,
                sizeImg: this.size,
                areaRecognition: this.pointAI && this.pointAI.length > 0 ? JSON.stringify(this.pointAI) : null,
                timeUse: this.weekUse ? JSON.stringify(this.weekUse) : null,
                timeNotification: this.weekNotify ? JSON.stringify(this.weekNotify) : null,
                appMobile: this.isMobileApp ? 1 : 0,
                phoneNumber: this.phoneNumber,
                emails: this.emails && this.emails.length > 0 ? JSON.stringify(this.emails) : null,
                period: this.period
            }
            this.$vs.loading();
            let url = this.isUpdate ? `/fire-detect/update?id=${this.id}` : "/fire-detect/create";
            this.$crm.post(url, dataRequest).then(() => {
                this.$vs.notify({
                    text: this.isUpdate ? this.$t('menuIntrusion.updateEventSuccess') : this.$t('menuIntrusion.addEventSuccess'),
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'success'
                });
                this.backToAiRes();
            }).catch(err => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            });
        },
    },
    mounted() {
        this.$eventBus.$on(this.nameEventBusUse, (data) => {
            this.weekUse = data.data;
        });
        this.$eventBus.$on(this.nameEventBusNotify, (data) => {
            this.weekNotify = data.data;
        });
    },

    watch: {
    }
}
</script>

<style lang="scss" scoped>
.vue-form-wizard {
    padding-bottom: 0px !important;
}

.back-to-list {
    margin-top: 10px;
}
</style>
<style lang="scss">
.vue-form-wizard .wizard-icon-circle .wizard-icon-container {
    width: 100%;
    height: 100%;
}
</style>
